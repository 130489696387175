import * as types from "../actions/action-types";

const defaultState = "morse";

export const nav = (state = defaultState, action) => {
  switch (action.type) {
    case types.NAVIGATE:
      return action.page;
    default:
      return state;
  }
};
