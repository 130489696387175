import React from "react";
import styled from "styled-components";
import { Icon } from "../components/Icon";

const Flag7 = styled(Icon)`
  transform: rotate(-30deg) scaleX(-1);
`;
const Flag8 = styled(Icon)``;
const Flag9 = styled(Icon)`
  transform: rotate(45deg);
`;

const Flag4 = styled(Icon)`
  transform: rotate(-90deg) scaleX(-1);
`;

const Flag6 = styled(Icon)`
  transform: rotate(90deg);
`;

const Flag1 = styled(Icon)`
  transform: rotate(-135deg) scaleX(-1);
`;
const Flag2 = styled(Icon)`
  transform: rotate(180deg);
`;
const Flag3 = styled(Icon)`
  transform: rotate(135deg);
`;

const FLAGS = {
  8: <Flag8 name="outlined_flag" />,
  7: <Flag7 name="outlined_flag" />,
  9: <Flag9 name="outlined_flag" />,
  4: <Flag4 name="outlined_flag" />,
  6: <Flag6 name="outlined_flag" />,
  1: <Flag1 name="outlined_flag" />,
  2: <Flag2 name="outlined_flag" />,
  3: <Flag3 name="outlined_flag" />
};

export const getFlagIcon = num => FLAGS[num];
