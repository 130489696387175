import * as types from "./action-types";

export const morseDot = _ => {
  return {
    type: types.MORSE_ADD_DOT
  };
};

export const morseDash = _ => {
  return {
    type: types.MORSE_ADD_DASH
  };
};

export const morseStop = _ => {
  return {
    type: types.MORSE_STOP
  };
};

export const morseClear = _ => {
  return {
    type: types.MORSE_CLEAR
  };
};

export const morseBackspace = _ => {
  return {
    type: types.MORSE_BACKPACE
  };
};
