export const NAVIGATE = "NAVIGATE";

export const MORSE_ADD_DOT = "MORSE_ADD_DOT";
export const MORSE_ADD_DASH = "MORSE_ADD_DASH";
export const MORSE_STOP = "MORSE_STOP";
export const MORSE_CLEAR = "MORSE_CLEAR";
export const MORSE_BACKPACE = "MORSE_BACKPACE";

export const TO_MORSE_VALUE_CHANGE = "TO_MORSE_VALUE_CHANGE";

export const FROM_SEMAPHORE_FLAG_SELECTED = "FROM_SEMAPHORE_FLAG_SELECTED";
export const FROM_SEMAPHORE_FLAG_DESELECTED = "FROM_SEMAPHORE_FLAG_DESELECTED";
export const FROM_SEMAPHORE_FLAG_ACCEPTED = "FROM_SEMAPHORE_FLAG_ACCEPTED";
export const FROM_SEMAPHORE_FLAG_CLEAR = "FROM_SEMAPHORE_FLAG_CLEAR";
export const FROM_SEMAPHORE_FLAG_BACKSPACE = "FROM_SEMAPHORE_FLAG_BACKSPACE";
