export const SEMAPHORE_LETTERS = {
  "12": "a",
  "24": "b",
  "27": "c",
  "28": "d",
  "29": "e",
  "26": "f",
  "23": "g",
  "14": "h",
  "17": "i",
  "68": "j",
  "18": "k",
  "19": "l",
  "16": "m",
  "13": "n",
  "47": "o",
  "48": "p",
  "49": "q",
  "46": "r",
  "34": "s",
  "78": "t",
  "79": "u",
  "38": "v",
  "69": "w",
  "39": "x",
  "67": "y",
  "36": "z",
  "22": " ",
  "89": "<#>"
};

const semaphoreLetterCommands = {
  "89": "numerals",
  "37": "cancel"
};

const semaphoreNumberCommands = {
  "37": "cancel",
  "68": "letters"
};

export const SEMAPHORE_NUMBERS = {
  "12": "1",
  "24": "2",
  "27": "3",
  "28": "4",
  "29": "5",
  "26": "6",
  "23": "7",
  "14": "8",
  "17": "9",
  "18": "0",
  "22": " ",
  "68": "<αβ>"
};

export const translateSemaphore = flags => {
  let mode = "letters";
  let commands = Object.keys(semaphoreLetterCommands);
  const output = [];
  for (let i = 0; i < flags.length; i++) {
    const currentFlag = flags[i];
    if (commands.indexOf(currentFlag) !== -1) {
      if (currentFlag === "89" && mode === "letters") {
        mode = "numerals";
        commands = Object.keys(semaphoreNumberCommands);
      } else if (currentFlag === "68" && mode === "numerals") {
        mode = "letters";
        commands = Object.keys(semaphoreLetterCommands);
      }
    } else {
      const lookup = mode === "letters" ? SEMAPHORE_LETTERS : SEMAPHORE_NUMBERS;
      output.push(lookup[currentFlag]);
    }
  }
  return output.join("");
};
