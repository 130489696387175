import * as types from "../actions/action-types";

const defaultState = "";

export const toMorse = (state = defaultState, action) => {
  switch (action.type) {
    case types.TO_MORSE_VALUE_CHANGE:
      return action.payload;
    default:
      return state;
  }
};
