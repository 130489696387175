const morseTable = {
  ".-": "a",
  "-...": "b",
  "-.-.": "c",
  "-..": "d",
  ".": "e",
  "..-.": "f",
  "--.": "g",
  "....": "h",
  "..": "i",
  ".---": "j",
  "-.-": "k",
  ".-..": "l",
  "--": "m",
  "-.": "n",
  "---": "o",
  ".--.": "p",
  "--.-": "q",
  ".-.": "r",
  "...": "s",
  "-": "t",
  "..-": "u",
  "...-": "v",
  ".--": "w",
  "-..-": "x",
  "-.--": "y",
  "--..": "z",
  ".----": "1",
  "..---": "2",
  "...--": "3",
  "....-": "4",
  ".....": "5",
  "-....": "6",
  "--...": "7",
  "---..": "8",
  "----.": "9",
  "-----": "0",

  // international
  ".-.-": "ä",
  ".--.-": "á",
  // ".--.-": "å",
  "----": "Ch",
  "..-..": "é",
  "--.--": "ñ",
  "---.": "ö",
  "	..--": "ü",

  // punctuation
  ".-...": "&",
  ".----.": "'",
  ".--.-.": "@",
  "-.--.-": ")",
  "-.--.": "(",
  "	---...": ":",
  "--..--": ",",
  "-...-": "=",
  "-.-.--": "!",
  ".-.-.-": ".",
  "-....-": "-",
  ".-.-.": "+",
  ".-..-.": '"',
  "..--..": "?",
  "-..-.": "/"
};

const reverseLookup = Object.keys(morseTable)
  .map(key => ({
    [morseTable[key]]: key
  }))
  .reduce((accum, entry) => ({ ...accum, ...entry }));

export const getMorse = letter => {
  if (reverseLookup.hasOwnProperty(letter)) {
    return reverseLookup[letter];
  } else {
    return null;
  }
};

export const translateFromMorse = code => {
  if (morseTable.hasOwnProperty(code)) {
    return morseTable[code];
  } else {
    return null;
  }
};

export const getPossibleCodesFromLetter = letter => {
  return getPossibleCodes(getMorse(letter));
};

export const getPossibleCodes = code => {
  const keys = Object.keys(morseTable)
    .sort((a, b) => a.length - b.length)
    .filter(key => key.startsWith(code));
  return keys;
};

export const getPossibleLetters = code => {
  const keys = getPossibleCodes(code);
  return keys.map(key => morseTable[key]);
};

export const getPossibleLettersFromLetter = letter => {
  return getPossibleLetters(getMorse(letter));
};
