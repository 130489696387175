import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  morseDot,
  morseDash,
  morseBackspace,
  morseStop,
  morseClear
} from "../actions/morse-actions";
import styled from "styled-components";
import {
  selectTotalMorseAlpha,
  selectCurrentMorse,
  selectTotalMorse,
  selectPossibleMorse
} from "../selectors/morse";
import { Icon } from "../components/Icon";
import { MorseCodeDisplay } from "./MorseCodeDisplay";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AppClient = styled.div`
  flex-grow: 1;
  padding: 20px;
`;

const PrimaryButton = styled.button`
  height: 100px;
  width: 20%;
  max-width: 100px;
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
`;

const SmallIcons = styled.div`
  .material-icons {
    font-size: 10px;
    color: grey;
  }
`;

const PrimaryDisplay = styled.div`
  height: 165px;
  width: 100%;
`;

const CurrentAlpha = styled.div`
  display: block;
  font-size: 60px;
`;

const Underlined = styled.span`
  border-bottom: 1px solid grey;
`;

const App = props => {
  return (
    <AppWrapper>
      <AppClient>
        <PrimaryDisplay>
          {props.currentChar ? "" : "Enter some dots and dashes"}
          <CurrentAlpha>{props.currentChar}</CurrentAlpha>
          <div>
            <MorseCodeDisplay morse={props.currentMorse} />
          </div>
        </PrimaryDisplay>
        <div>
          {props.totalAlpha}
          <Underlined>{props.currentChar}</Underlined>
        </div>
        <div>
          <SmallIcons>
            <MorseCodeDisplay morse={props.totalMorse} />
            <Underlined>
              <MorseCodeDisplay morse={props.currentMorse} />
            </Underlined>
          </SmallIcons>
        </div>
      </AppClient>
      <ButtonBar>
        <PrimaryButton onClick={props.morseDot}>
          <Icon name={"fiber_manual_record"} />
        </PrimaryButton>
        <PrimaryButton onClick={props.morseDash}>
          <Icon name={"remove"} />
        </PrimaryButton>
        <PrimaryButton onClick={props.morseStop}>
          <Icon name={"check"} />
        </PrimaryButton>
        <PrimaryButton onClick={props.morseBackspace}>
          <Icon name={"undo"} />
        </PrimaryButton>
        <PrimaryButton onClick={props.morseClear}>
          <Icon name={"clear"} />
        </PrimaryButton>
      </ButtonBar>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  const possibleMorse = selectPossibleMorse(state);
  return {
    totalAlpha: selectTotalMorseAlpha(state),
    totalMorse: selectTotalMorse(state),
    currentMorse: selectCurrentMorse(state),
    possibleMorse: possibleMorse,
    currentChar: possibleMorse.length ? possibleMorse[0].character : ""
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      morseDot,
      morseDash,
      morseStop,
      morseBackspace,
      morseClear
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
