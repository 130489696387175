import * as types from "../actions/action-types";

const defaultState = {
  currentFlags: [],
  flags: []
};

export const fromSemaphore = (state = defaultState, action) => {
  switch (action.type) {
    case types.FROM_SEMAPHORE_FLAG_SELECTED:
      return {
        ...state,
        currentFlags: [...state.currentFlags, action.flag]
      };
    case types.FROM_SEMAPHORE_FLAG_DESELECTED:
      return {
        ...state,
        currentFlags: state.currentFlags.filter(flag => flag !== action.flag)
      };
    case types.FROM_SEMAPHORE_FLAG_ACCEPTED:
      const copy = [...state.currentFlags];
      copy.sort();
      return {
        flags: [...state.flags, copy.join("")],
        currentFlags: []
      };
    case types.FROM_SEMAPHORE_FLAG_BACKSPACE:
      if (!state.flags.length) {
        return {
          ...state,
          flags: [...state.flags.slice(0, state.flags.length - 1)]
        };
      } else {
        return {
          ...state,
          currentFlags: [
            ...state.currentFlags.slice(0, state.currentFlags.length - 1)
          ]
        };
      }
    case types.FROM_SEMAPHORE_FLAG_CLEAR:
      return defaultState;
    default:
      return state;
  }
};
