import * as types from "../actions/action-types";

const defaultState = "";

export const morse = (state = defaultState, action) => {
  switch (action.type) {
    case types.MORSE_ADD_DOT:
      return state + ".";
    case types.MORSE_ADD_DASH:
      return state + "-";
    case types.MORSE_STOP:
      if (state[state.length - 1] === "!") {
        return state;
      } else if (state[state.length - 1] === "x") {
        return state.substr(0, state.length - 1) + "!";
      } else {
        return state + "x";
      }
    case types.MORSE_BACKPACE:
      const end = state.substr(state.length - 2, state.length - 1);
      const hasControlCharacter =
        end.indexOf("x") !== -1 || end.indexOf("!") !== -1;
      return state.substr(0, state.length - (hasControlCharacter ? 2 : 1));
    case types.MORSE_CLEAR:
      return defaultState;
    default:
      return state;
  }
};
