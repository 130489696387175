import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import Morse from "./morse/Morse";
import { ToMorse } from "./morse/ToMorse";
import { FromSemaphore } from "./semaphore/FromSemaphore";
import {
  navigateToMorse,
  navigateToFromSemaphore,
  navigateToToMorse
} from "./actions/navigation-actions";

const AppWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AppClient = styled.div`
  flex-grow: 1;
`;

const NavButton = styled.button`
  height: 50px;
  width: 20%;
  max-width: 100px;
`;

const TopButtonBar = styled.div`
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;
`;

const getPage = page => {
  switch (page) {
    case "morse":
      return <Morse />;
    case "to-morse":
      return <ToMorse />;
    case "from-semaphore":
      return <FromSemaphore />;
    default:
      return "?";
  }
};

const App = props => {
  return (
    <AppWrapper>
      <TopButtonBar>
        <NavButton onClick={props.navigateToMorse}>From Morse</NavButton>
        <NavButton onClick={props.navigateToToMorse}>To Morse</NavButton>
        <NavButton onClick={props.navigateToFromSemaphore}>
          From Semaphore
        </NavButton>
      </TopButtonBar>
      <AppClient>{getPage(props.nav)}</AppClient>
    </AppWrapper>
  );
};

const mapStateToProps = state => ({
  nav: state.nav
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigateToMorse,
      navigateToToMorse,
      navigateToFromSemaphore
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
