import { morse } from "./morse-reducer.js";
import { nav } from "./nav-reducer.js";
import { toMorse } from "./to-morse-reducer.js";
import { fromSemaphore } from "./from-semaphore-reducer.js";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  morse,
  toMorse,
  fromSemaphore,
  nav
});

export default rootReducer;
