import {
  translateSemaphore,
  SEMAPHORE_LETTERS,
  SEMAPHORE_NUMBERS
} from "../pure/semaphore";

export const selectTranslatedSemaphore = state =>
  translateSemaphore(state.fromSemaphore.flags);

export const selectPossibleLetters = state => {
  if (!state.fromSemaphore.currentFlags.length) {
    return {};
  }

  const flag = state.fromSemaphore.currentFlags[0];

  const mapping = selectIsInNumberMode(state)
    ? SEMAPHORE_NUMBERS
    : SEMAPHORE_LETTERS;

  return Object.keys(mapping)
    .filter(key => key.indexOf("" + flag) !== -1)
    .map(key => ({ key: key, value: mapping[key] }))
    .map(entry => {
      const possibleFlags = entry.key.split("");
      const otherFlag = possibleFlags[1 - possibleFlags.indexOf("" + flag)];
      return {
        [otherFlag]: entry.value
      };
    })
    .reduce((accum, entry) => ({ ...accum, ...entry }));
};

export const selectIsInNumberMode = state =>
  state.fromSemaphore.flags
    .filter(entry => entry === "89" || entry === "68")
    .pop() === "89";
