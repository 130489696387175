import { getMorse } from "../pure/morse";

export const selectTotalMorseFromWords = state =>
  getTotalMorseFromWords(state.toMorse);

export const selectToMorseValue = state => state.toMorse;

const getTotalMorseFromWords = words =>
  words
    .split(" ")
    .map(getMorseFromWord)
    .join("!");

const getMorseFromWord = word =>
  word
    .split("")
    .map(getMorse)
    .join("x");
