import React from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  flagSelected,
  flagDeselected,
  flagAccepted,
  flagBackspace,
  flagClear
} from "../actions/from-semaphore-actions";
import {
  selectTranslatedSemaphore,
  selectPossibleLetters
} from "../selectors/from-semaphore";
import { Icon } from "../components/Icon";
import { getFlagIcon } from "./FlagIcon";
import { SemaphoreCharacter } from "./SemaphoreCharacter";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AppClient = styled.div`
  flex-grow: 1;
  padding: 20px;
`;

const SemaphoreButton = styled.button`
  color: ${props => (props.selected ? "red" : "black")}
  height: 60px;
  width: 60px;
`;

const SemaphoreDirectionButton = styled(SemaphoreButton)`
  font-size: 24px;
`;

const SemaphoreButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const makeButton = (value, props) => {
  const selected = props.selectedFlag.indexOf(value) !== -1;
  const flagsFull = props.selectedFlag.length === 2;
  const hasPossibleLetter = !!props.possibleLetters[value];
  const noFlagsSelected = props.selectedFlag.length === 0;
  const enabled =
    (!flagsFull && hasPossibleLetter) || selected || noFlagsSelected;
  const disabled = !enabled;
  const content =
    Object.keys(props.possibleLetters).length && !selected
      ? props.possibleLetters[value]
      : getFlagIcon(value);
  return (
    <SemaphoreDirectionButton
      onClick={_ =>
        selected ? props.flagDeselected(value) : props.flagSelected(value)
      }
      selected={selected}
      disabled={disabled}
    >
      {disabled ? "" : content}
    </SemaphoreDirectionButton>
  );
};

const Component = props => {
  return (
    <AppWrapper>
      <AppClient>
        <h1>{props.text}</h1>
        {props.totalFlags.length
          ? props.totalFlags.map((char, index) => (
              <SemaphoreCharacter key={index} character={char} />
            ))
          : "Hit some flags"}
      </AppClient>
      <SemaphoreButtonRow>
        {makeButton(7, props)}
        {makeButton(8, props)}
        {makeButton(9, props)}
      </SemaphoreButtonRow>
      <SemaphoreButtonRow>
        {makeButton(4, props)}
        <SemaphoreButton onClick={_ => props.flagAccepted()}>
          <Icon name={"check"} />
        </SemaphoreButton>
        {makeButton(6, props)}
      </SemaphoreButtonRow>
      <SemaphoreButtonRow>
        {makeButton(1, props)}
        {makeButton(2, props)}
        {makeButton(3, props)}
      </SemaphoreButtonRow>
      <div>
        <SemaphoreButton onClick={_ => props.flagClear()}>
          Clear
        </SemaphoreButton>
      </div>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  return {
    state: state.fromSemaphore,
    text: selectTranslatedSemaphore(state),
    selectedFlag: state.fromSemaphore.currentFlags,
    possibleLetters: selectPossibleLetters(state),
    totalFlags: state.fromSemaphore.flags
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { flagSelected, flagDeselected, flagAccepted, flagBackspace, flagClear },
    dispatch
  );
}

export const FromSemaphore = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
