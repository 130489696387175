import * as types from "./action-types";

const navigate = page => {
  return {
    type: types.NAVIGATE,
    page
  };
};

export const navigateToMorse = _ => navigate("morse");
export const navigateToToMorse = _ => navigate("to-morse");
export const navigateToFromSemaphore = _ => navigate("from-semaphore");
