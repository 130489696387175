import React from "react";
import styled from "styled-components";
import { Icon } from "../components/Icon";

const MorseSeparator = styled.span`
  width: 12px;
  display: inline-block;
`;

const MorseContainer = styled.span`
  font-size: 24px;
  white-space: normal;
`;

const MorseWordContainer = styled.div`
  &:not(last-child()) {
    margin-right: 12px;
  }
  white-space: nowrap;
  display: inline-block;
`;

const MorseWord = props => (
  <MorseWordContainer>{props.morse.split("").map(morseBit)}</MorseWordContainer>
);

const morseBit = (bit, index) => {
  switch (bit) {
    case "-":
      return <Icon key={index} name={"remove"} />;
    case ".":
      return <Icon key={index} name={"fiber_manual_record"} />;
    case "x":
      return "/";
    case "!":
      return <MorseSeparator key={index} />;
    default:
      return "?";
  }
};

export const MorseCodeDisplay = props => {
  return (
    <MorseContainer>
      {props.morse.split("!").map(word => (
        <MorseWord morse={word} />
      ))}
    </MorseContainer>
  );
};
