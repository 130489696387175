import React from "react";
import styled from "styled-components";
import { getFlagIcon } from "./FlagIcon";
import { Icon } from "../components/Icon";

const SemaphoreContainer = styled.div`
  font-size: 24px;
  white-space: normal;
  width: 60px;
  height: 60px;
  display: inline-block;
  position: relative;
  margin: 5px;
  border-bottom: 1px solid black;
`;

const FlagBase = styled.div`
  height: 24px;
  width: 24px;
  position: absolute;
`;

const Center = styled(FlagBase)`
  bottom: calc(50% - 12px);
  left: calc(50% - 12px);
`;

const Flag1 = styled(FlagBase)`
  bottom: 0;
  left: 0;
`;

const Flag2 = styled(FlagBase)`
  bottom: 0;
  left: calc(50% - 12px);
`;

const Flag3 = styled(FlagBase)`
  bottom: 0;
  right: 0;
`;

const Flag4 = styled(FlagBase)`
  bottom: calc(50% - 12px);
  left: 0;
`;

const Flag6 = styled(FlagBase)`
  bottom: calc(50% - 12px);
  right: 0;
`;

const Flag7 = styled(FlagBase)`
  top: 0;
  left: 0;
`;

const Flag8 = styled(FlagBase)`
  top: 0;
  left: calc(50% - 12px);
`;

const Flag9 = styled(FlagBase)`
  top: 0;
  right: 0;
`;

const FLAGS = {
  1: <Flag1>{getFlagIcon(1)}</Flag1>,
  2: <Flag2>{getFlagIcon(2)}</Flag2>,
  3: <Flag3>{getFlagIcon(3)}</Flag3>,
  4: <Flag4>{getFlagIcon(4)}</Flag4>,
  7: <Flag7>{getFlagIcon(7)}</Flag7>,
  6: <Flag6>{getFlagIcon(6)}</Flag6>,
  8: <Flag8>{getFlagIcon(8)}</Flag8>,
  9: <Flag9>{getFlagIcon(9)}</Flag9>
};

export const SemaphoreCharacter = props => {
  return (
    <SemaphoreContainer>
      {props.character.split("").map(flag => FLAGS[flag])}
      <Center>
        <Icon name="tag_faces" />
      </Center>
    </SemaphoreContainer>
  );
};
