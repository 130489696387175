import * as types from "./action-types";

export const FROM_SEMAPHORE_FLAG_SELECTED = "FROM_SEMAPHORE_FLAG_SELECTED";
export const FROM_SEMAPHORE_FLAG_DESELECTED = "FROM_SEMAPHORE_FLAG_DESELECTED";
export const FROM_SEMAPHORE_FLAG_ACCEPTED = "FROM_SEMAPHORE_FLAG_ACCEPTED";
export const FROM_SEMAPHORE_FLAG_CLEAR = "FROM_SEMAPHORE_FLAG_CLEAR";
export const FROM_SEMAPHORE_FLAG_BACKSPACE = "FROM_SEMAPHORE_FLAG_BACKSPACE";

export const flagSelected = flag => {
  return {
    type: types.FROM_SEMAPHORE_FLAG_SELECTED,
    flag
  };
};

export const flagDeselected = flag => {
  return {
    type: types.FROM_SEMAPHORE_FLAG_DESELECTED,
    flag
  };
};

export const flagAccepted = _ => {
  return {
    type: types.FROM_SEMAPHORE_FLAG_ACCEPTED
  };
};

export const flagClear = _ => {
  return {
    type: types.FROM_SEMAPHORE_FLAG_CLEAR
  };
};

export const flagBackspace = _ => {
  return {
    type: types.FROM_SEMAPHORE_FLAG_BACKSPACE
  };
};
