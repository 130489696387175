import { translateFromMorse, getPossibleCodes } from "../pure/morse";

const selectMorse = state => state.morse;

export const selectCurrentMorse = state => {
  const morse = selectMorse(state).split(/x|!/);
  return morse[morse.length - 1];
};

export const selectTotalMorse = state => {
  const morse = selectMorse(state);
  const charBreakIdx = morse.lastIndexOf("x");
  const wordBreakIdx = morse.lastIndexOf("!");
  return morse.substr(0, Math.max(charBreakIdx, wordBreakIdx) + 1);
};

export const selectTotalMorseAlpha = state =>
  selectMorseWords(selectTotalMorse(state));

export const selectMorseWords = chars => {
  return chars
    .split("!")
    .map(parseWord)
    .join(" ");
};

const parseWord = word => {
  const letters = word.split("x");
  return letters.map(translateFromMorse).join("");
};

export const selectPossibleMorse = state => {
  if (selectCurrentMorse(state) === "") {
    return [];
  }
  return getPossibleCodes(selectCurrentMorse(state)).map(code => ({
    code: code,
    character: translateFromMorse(code)
  }));
};
