import React from "react";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toMorseValueChange } from "../actions/to-morse-actions";
import {
  selectTotalMorseFromWords,
  selectToMorseValue
} from "../selectors/to-morse";
import { MorseCodeDisplay } from "./MorseCodeDisplay";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AppClient = styled.div`
  flex-grow: 1;
  padding: 20px;
`;

const InputBar = styled.div`
  input {
    font-size: 24px;
  }
`;

const ToMorseComponent = props => {
  return (
    <AppWrapper>
      <AppClient>
        Enter text to translate to morse code
        <div>
          <h1>{props.alpha}</h1>
          <MorseCodeDisplay morse={props.morse} />
        </div>
      </AppClient>
      <InputBar>
        <input
          value={props.alpha}
          onChange={e => props.toMorseValueChange(e.target.value)}
        />
      </InputBar>
    </AppWrapper>
  );
};

function mapStateToProps(state) {
  return {
    morse: selectTotalMorseFromWords(state),
    alpha: selectToMorseValue(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toMorseValueChange
    },
    dispatch
  );
}

export const ToMorse = connect(
  mapStateToProps,
  mapDispatchToProps
)(ToMorseComponent);
