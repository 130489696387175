import rootReducer from '../reducers';
import { applyMiddleware, createStore } from "redux";
import { save, load } from "redux-localstorage-simple";

export default (initialState) => {
  const createStoreWithMiddleware
    = applyMiddleware(
        save() // Saving done here
    )(createStore)

  return createStoreWithMiddleware(
            rootReducer,
            load(),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
};
